import { type FC } from 'react';
import { TiLocation } from 'react-icons/ti';

import type { VoyagerResult } from '@core/ts/results';

import { CTA_LABEL } from '../../constants';
import styles from './MobileStickyCta.module.css';

type Props = {
  result: VoyagerResult;

  onSubmit: () => void;
  onSkip: () => void;
};

const MobileStickyCta: FC<Props> = ({ result: { school }, onSubmit, onSkip }) => (
  <div className={styles.mobileCta}>
    <div className={styles.mobileCtaSchool}>
      <h3>{school.name}</h3>
      {school.state && school.city && (
        <div className={styles.mobileCtaLocation}>
          <TiLocation size={16} />
          <p>
            {school.city}, {school.state}
          </p>
        </div>
      )}
    </div>
    <button
      data-tf-element-role="submit"
      className={styles.mobileSubmitInfoBtn}
      id="reward_click_popup"
      onClick={onSubmit}
      type="submit"
    >
      {CTA_LABEL}
    </button>
    <button type="button" onClick={onSkip} className={styles.skipToResults}>
      Back to all results &gt;&gt;
    </button>
  </div>
);

export default MobileStickyCta;
