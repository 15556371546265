import { FC, useEffect } from 'react';
import { TiClipboard } from 'react-icons/ti';
import { useSelector } from 'react-redux';

import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { selectViewCorrelationIdMap } from '@core/reducers/eventingSlice';
import { PageActions } from '@core/services/newRelic';
import { Program } from '@core/ts/results';
import cn from '@core/utils/classNames';

import { useGetApplicationCtaData } from '../../hooks/useGetApplicationCtaData';
import styles from './Application.module.css';

type Props = {
  program: Program;
  eventingOverrides?: Record<string, any>;
  isRecommender?: boolean;
};

const Application: FC<Props> = ({ program, eventingOverrides, isRecommender }) => {
  const cta = useGetApplicationCtaData({ program });
  const viewCorrelationIdMap = useSelector(selectViewCorrelationIdMap);
  const flags = useFeatureFlags();

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId: viewCorrelationIdMap[program.id],
    webElement: {
      elementType: 'linkout-cta',
      text: 'Start My Application',
      location: eventingOverrides?.location || 'full-screen',
      htmlId: 'voyager-application-cta',
      name: 'application-cta',
    },
  });
  const onCtaClick = () => {
    elementClicked();
    window.open(cta.url, '_blank');
    const actionParams = {
      ...flags,
      anonymousId: window._Cohesion.anonymousId,
      correlationId: viewCorrelationIdMap[program.id],
    };
    PageActions.ApplicationCtaClicked(actionParams);
  };

  useEffect(() => {
    const actionParams = {
      ...flags,
      anonymousId: window._Cohesion.anonymousId,
      correlationId: viewCorrelationIdMap[program.id],
    };
    if (cta?.label && cta?.url) {
      elementViewed();
      PageActions.ApplicationCtaViewed(actionParams);
    }
  }, [cta]);

  return (
    cta &&
    cta.url !== '' && (
      <div className={cn(styles.applicationCta, isRecommender && styles.isRecommender)}>
        <span className={styles.applicationCtaHeading}>
          <TiClipboard size="1.5rem" />
          <h2 className={styles.ctaHeading}>Apply Online Today!</h2>
        </span>
        <p>Online applications are fast, convenient, and let you track your acceptance status. Start yours today!</p>
        <button type="button" className={cn(styles.ctaButton, styles.isRecommender)} onClick={onCtaClick}>
          {cta.label}
        </button>
      </div>
    )
  );
};
Application.defaultProps = {
  eventingOverrides: {},
};
export default Application;
