import { type IdSchema } from '@rjsf/utils';
import { type FC } from 'react';

import PostResultQuestionForm from '@core/shared/features/PostResultForm/index2';
import type { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import { FlowType } from '../../types';
import DoubleChevronBackButton from '../CtaButtons/DoubleChevronBackButton';
import PopupFormTemplate from '../PopupFormTemplate';
import TCPA from '../TCPA';
import styles from './AdditionalQuestions.module.css';

type Props = {
  selectedResult: VoyagerResult;
  prqSchema: IdSchema;
  onFormSubmit: () => void;
  eventingOverrides?: Record<string, unknown>;
  onBackButtonClick?: () => void;
  flowType?: FlowType;
  result: VoyagerResult;
  isSubmittingLead: boolean;
};

const AdditionalQuestions2: FC<Props> = ({
  selectedResult,
  prqSchema,
  onFormSubmit,
  eventingOverrides,
  onBackButtonClick,
  result,
  flowType = FlowType.POPUP,
  isSubmittingLead,
}) => {
  const Wrapper = flowType === FlowType.POPUP ? PopupFormTemplate : 'div';

  return (
    <Wrapper result={selectedResult}>
      <div className={cn(styles.additionalQuestions, flowType === FlowType.DRAWER && styles.drawerExperience)}>
        <section className={styles.leftSide}>
          <div className={styles.heading}>
            <h3>{selectedResult.school.name} requires some additional info before submitting.</h3>
          </div>
          <div className={styles.desktopBackButton}>
            <DoubleChevronBackButton onClick={onBackButtonClick} />
          </div>
        </section>
        <section className={styles.rightSide}>
          <PostResultQuestionForm
            className={styles.form}
            selectedResult={selectedResult}
            prqSchema={prqSchema}
            onFormSubmit={onFormSubmit}
            eventingOverrides={eventingOverrides}
            result={result}
          >
            <button disabled={isSubmittingLead} className={styles.submitBtn} type="submit">
              Confirm and Submit
            </button>
            <div className={styles.mobileBackButton}>
              <DoubleChevronBackButton onClick={onBackButtonClick} />
            </div>
          </PostResultQuestionForm>
        </section>
      </div>
      {flowType === FlowType.DRAWER && <TCPA school={selectedResult?.school} />}
    </Wrapper>
  );
};

export default AdditionalQuestions2;
