import { type ReactNode, type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import SchoolInfoHeader from '../SchoolInfoHeader';
import TCPA from '../TCPA';
import styles from './PiiFormTemplate.module.css';

type Props = {
  result?: VoyagerResult;
  children: ReactNode;
};

const PopupFormTemplate: FC<Props> = ({ result, children }) => (
  <form onSubmit={(e) => e.preventDefault()} data-tf-element-role="offer">
    <SchoolInfoHeader result={result} />
    <div className={styles.piiConfirmationContainer}>
      <div className={styles.piiConfirmationFormColumns}>{children}</div>

      <TCPA school={result?.school} />
    </div>
  </form>
);

export default PopupFormTemplate;
