import { FC, MouseEventHandler, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { EmbeddedRecommenderCardTest } from '@core/blocks/edu-match/components/EmbeddedRecommender/EmbeddedRecommenderCardTest';
import { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import { RecommenderCardResults } from '@core/blocks/edu-match/components/RecommenderCardResults';
import { LEAD_SUBMISSION_STEPS } from '@core/constants';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useProductEvents from '@core/hooks/cohesion/useProductEvents';
import useIntersectionObserver from '@core/hooks/useIntersectionObserver';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import {
  selectListId,
  setProductCorrelationIdMapEntry,
  setViewCorrelationIdMapEntry,
} from '@core/reducers/eventingSlice';
import {
  selectSubmissions,
  setShowDuplicatePopup,
  setLeadDelivery,
  selectIsRecommenderPopupOpen,
} from '@core/reducers/matchesSlice';
import { EnhancedSchoolData } from '@core/services/hub';
import { SpotlightCard } from '@core/shared/features/Recommender/Revamp/SpotlightProgramRevamp/SpotlightCard';
import { VoyagerResult } from '@core/ts/results';

import { useGetApplicationCtaData } from '../../hooks/useGetApplicationCtaData';
import Drawer from '../Drawer';
import DefaultCard from './DefaultCard/DefaultCard';
import ResultsEngagementCard from './ResultsEngagementCard';

const { PII_CONFIRMATION, EXIT_PAGE } = LEAD_SUBMISSION_STEPS;

type Props = {
  isUGToGradResultsPageABTestSpotlight?: boolean;
  isSingleResultPopupRecommender?: boolean;
  isRecommenderPopup?: boolean;
  isSingleResultRecommender?: boolean;
  isRecommender?: boolean;
  isSpotlight?: boolean;
  result: VoyagerResult;
  eventingOverrides?: any;
  position?: number;
  ctaLabel?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  enhancedSchoolData?: EnhancedSchoolData;
};

const Card: FC<Props> = ({
  isSingleResultPopupRecommender,
  isSingleResultRecommender,
  isRecommenderPopup,
  isRecommender,
  isSpotlight,
  result,
  eventingOverrides,
  position,
  ctaLabel,
  onClick,
  enhancedSchoolData,
}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerStep, setDrawerStep] = useState<string>(PII_CONFIRMATION);
  const listId = useSelector(selectListId);
  const submissions = useSelector(selectSubmissions);
  const showRecommendedResults = useSelector(selectIsRecommenderPopupOpen);

  const { program, school } = result;
  const { data }: SchoolConfigData = useSchoolConfig(result.school.id);

  const isProgramSubmitted = submissions.some((s) => s.program.id === program.id);

  const cta = useGetApplicationCtaData({ program });

  const cardRef = useRef<HTMLDivElement>();

  const isInView = useIntersectionObserver(cardRef);

  const viewCorrelationId: string = useMemo(() => uuid(), []);
  const dispatch = useDispatch();

  // grabs product event
  const { productLoaded, productViewed, productClicked } = useProductEvents({
    listId,
    viewCorrelationId,
    product: {
      sku: String(result.cap.id),
      variant: program.degree.slug,
      productId: String(program.id),
      name: program.subject.slug,
      category: program.category.slug,
      brand: school.slug,
      position,
      location: eventingOverrides?.location || 'voyager-results-page',
      formatType: 'app',
      formatSubtype: 'grid',
      positionEngine: 'algolia',
      ...eventingOverrides.product,
    },
    customDimensions: eventingOverrides.customDimensions,
  });

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    if (!isProgramSubmitted && !showRecommendedResults) {
      productLoaded();
      dispatch(setViewCorrelationIdMapEntry({ key: [program.id], value: viewCorrelationId }));
    }
  }, [showRecommendedResults]);

  // fire product clicked when is in view and the recommender popup is closed
  useEffect(() => {
    if (isInView && !showRecommendedResults && !isProgramSubmitted) {
      productViewed();
    }

    // Fire Element Viewed for Application Cta if it is in view, the program is submitted, the drawer is not open,
    // the recommender popup is not open and the card is not a recommender card, and the cta exists
    if (
      isInView &&
      isProgramSubmitted &&
      !drawerOpen &&
      !showRecommendedResults &&
      cta?.label &&
      cta?.url &&
      !isRecommender
    ) {
      elementViewed();
    }
  }, [isInView, showRecommendedResults, isProgramSubmitted, cta]);

  const isSchoolSubmitted: boolean = submissions?.some((submission) => submission.school.id === school.id);

  const handleLearnMore = (): void => {
    // If school is already submitted, show duplicate popup
    if (isSchoolSubmitted) {
      dispatch(
        setLeadDelivery({
          show: false,
          currentPrograms: [result],
          showExitStep: false,
        })
      );
      dispatch(setShowDuplicatePopup(true));
      return;
    }
    setDrawerOpen(true);

    // If program hasn't been submitted, show lead delivery slide-out
    const productCorrelationId: string = uuid();

    dispatch(setProductCorrelationIdMapEntry({ key: program.id, value: productCorrelationId }));
    productClicked({ correlationId: productCorrelationId });

    dispatch(
      setLeadDelivery({
        currentPrograms: [result],
        showExitStep: false,
      })
    );
  };

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId: viewCorrelationId,
    webElement: {
      elementType: 'linkout-cta',
      text: 'Start My Application',
      location: 'results-list',
      htmlId: 'voyager-application-cta',
      name: 'application-cta',
    },
  });
  const onCtaClick = () => {
    elementClicked();
    window.open(cta.url, '_blank');
  };
  const nextStepsClick = () => {
    setDrawerStep(EXIT_PAGE);
    setDrawerOpen(true);
  };

  let handleProductClick = onClick || handleLearnMore;
  if (isProgramSubmitted && handleProductClick !== onClick) {
    if (cta?.label && cta?.url) {
      handleProductClick = onCtaClick;
    } else {
      handleProductClick = nextStepsClick;
    }
  }

  const isPrivateTag = data?.tags?.isPrivate || school?.additional?.isPrivate;
  const isOnlineTag = data?.tags?.online || school?.additional?.isOnline;
  const isNonprofitTag = data?.tags?.notForProfit || school?.additional?.notForProfit;

  const cardProps = {
    school: result.school,
    program: result.program,
    cta,
    isRecommender,
    isSpotlight,
    isProgramSubmitted,
    tags: {
      isPrivateTag,
      isOnlineTag,
      isNonprofitTag,
    },
    cardRef: cardRef as MutableRefObject<HTMLDivElement>,
    ctaLabel,
    handleProductClick,
    enhancedSchoolData,
  };

  let Card = DefaultCard;
  if (featureFlags?.resultsPageEngagementTest === 'test' && isRecommender) {
    Card = isSingleResultRecommender ? EmbeddedRecommenderCardTest : ResultsEngagementCard;
  }
  if (featureFlags?.resultsPageEngagementTest === 'test' && isSpotlight) {
    Card = SpotlightCard;
  }
  if (featureFlags?.resultsPageEngagementTest === 'test' && isRecommenderPopup) {
    Card = isSingleResultPopupRecommender ? ResultsEngagementCard : RecommenderCardResults;
  }
  if (featureFlags?.resultsPageEngagementTest === 'test' && !isRecommender && !isSpotlight && !isRecommenderPopup) {
    Card = ResultsEngagementCard;
  }
  return (
    <>
      {drawerOpen && (
        <Drawer
          drawerStep={drawerStep}
          setDrawerStep={setDrawerStep}
          open={drawerOpen}
          setOpen={setDrawerOpen}
          result={result}
        />
      )}
      <Card {...cardProps} />
    </>
  );
};
Card.defaultProps = {
  isRecommender: false,
  eventingOverrides: {},
  position: 0,
};
export default Card;
