import { Dispatch, FC, SetStateAction, useState } from 'react';

import PiiFormSectionRevamp from '@core/shared/features/Recommender/Revamp/PiiFormSectionRevamp';

import styles from './ConfirmationRevamp.module.css';

type Props = {
  setFirstName: Dispatch<SetStateAction<string>>;
  setLastName: Dispatch<SetStateAction<string>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  piiLeadErrors: { field: string }[];
};

const ConfirmationRevamp: FC<Props> = ({ setFirstName, setLastName, setEmail, setPhone, piiLeadErrors }) => (
  <div className={styles.confirmationForm}>
    <PiiFormSectionRevamp
      setFirstName={setFirstName}
      setLastName={setLastName}
      setEmail={setEmail}
      setPhone={setPhone}
      piiLeadErrors={piiLeadErrors}
    />
  </div>
);

export default ConfirmationRevamp;
