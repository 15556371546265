import { type FC } from 'react';

import { appendAcronym } from '@core/shared/features/TcpaSection';
import { School } from '@core/ts/results';

import { CTA_LABEL } from '../../constants';
import { replaceCTALabelInTCPA } from '../../utils/tcpa';
import styles from './FormCtaDisclaimer.module.css';

const defaultTCPA = `By selecting "${CTA_LABEL}" I confirm that the information provided on this form is accurate and complete
and I agree to receive email communications, phone calls, and SMS/Text Messages from these schools at the numbers
provided above, including my wireless numbers if provided. Contact methods may include calls or texts made using
automated technology or pre-recorded voice messages. I understand that my consent is not a condition of purchasing
services from these schools.`;

type Props = {
  school?: School;
};

const FormCtaDisclaimer: FC<Props> = ({ school }) => (
  <>
    <div className={styles.piiInfoDisclaimerHeader}>
      Your information will never be shared with a school without your consent.
    </div>
    <div className={styles.consentLanguage}>
      <span className={styles.schoolName} data-tf-element-role="consent-advertiser-name">
        {appendAcronym(school?.name || '')}:
      </span>
      <div
        className={styles.piiInfoDisclaimerText}
        data-tf-element-role="consent-language"
        dangerouslySetInnerHTML={{ __html: replaceCTALabelInTCPA(school?.config?.tcpa || defaultTCPA) }}
      />
    </div>
  </>
);

export default FormCtaDisclaimer;
