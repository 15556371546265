import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setInput } from '@core/reducers/inputsSlice';

export const AddActiveProspectScript: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Set the token in the config slice for later use in building the lead. This callback is called by trust form.
    window.trustedFormCallback = function trustedFormCallback(value: string) {
      dispatch(setInput({ key: 'activeProspectToken', value }));
    };

    // Adds the script to the dom
    const l = new Date().getTime() + Math.random();
    const protocol = document.location.protocol === 'http:' ? 'http' : 'https';

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = `${protocol}://cdn.trustedform.com/bootstrap.js?provide_referrer=false&field=xxTrustedFormCertUrl&l=${l}&invert_field_sensitivity=false&use_tagged_consent=true`;

    document.body.appendChild(script);
  }, []);

  return null;
};
