import { SetStateAction, Dispatch, FC, useEffect } from 'react';
import { FaRegClock } from 'react-icons/fa';
import { HiOutlineChevronDoubleRight } from 'react-icons/hi';
import { TiLocation } from 'react-icons/ti';
import { useSelector } from 'react-redux';

import { applicationCtaBtn } from '@core/blocks/edu-match/components/ExitStrategy/eventPayloads';
import { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import { useGetApplicationCtaData } from '@core/blocks/eduResults/hooks/useGetApplicationCtaData';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import { selectViewCorrelationIdMap } from '@core/reducers/eventingSlice';
import { VoyagerInputs, selectAllInputs } from '@core/reducers/inputsSlice';
import NextStepsCopy from '@core/shared/features/Recommender/Revamp/NextStepsCopy';
import { VoyagerResult } from '@core/ts/results';

import Application from '../../Application';
import styles from './ExitPageRevamp.module.css';

type Props = {
  result: VoyagerResult;
  schoolConfigData: SchoolConfigData;
  setOpen: Dispatch<SetStateAction<boolean>>;
};
const ExitPageRevamp: FC<Props> = ({ result, schoolConfigData, setOpen }) => {
  const { school, program } = result;
  const { firstName }: VoyagerInputs = useSelector(selectAllInputs);
  const cta = useGetApplicationCtaData({
    program,
  });
  const viewCorrelationIdMap = useSelector(selectViewCorrelationIdMap);

  const { elementViewed, elementClicked } = useElementEvents({
    correlationId: viewCorrelationIdMap[program.id],
    webElement: {
      text: 'Back to Results',
      elementType: 'exit-element',
      location: 'drawer',
      name: 'exit-application-step',
      htmlId: 'exit-back',
    },
  });
  const handleDrawerClose = (): void => {
    elementClicked();
    setOpen(false);
  };
  const onCtaClick = () => {
    elementClicked(applicationCtaBtn);
    window.open(cta.url, '_blank');
  };
  useEffect(() => {
    elementViewed();
  }, []);

  return (
    <>
      <div className={styles.exitContainer}>
        <h2 className={styles.exitHeader}>Congratulations, {firstName.value}! </h2>
        <h1 className={styles.exitSuccess}>Your information was submitted succesfully.</h1>
        {schoolConfigData.showNextStartDate && (
          <p className={styles.acceptingStudentsCta}>
            <FaRegClock size="20px" /> Now Accepting Students
          </p>
        )}
        <div className={styles.appCta}>
          <Application program={result.program} isRecommender eventingOverrides={{ location: 'drawer' }} />
        </div>
        <NextStepsCopy />
      </div>

      {cta?.label && cta?.url && (
        <div className={styles.mobileCta}>
          <div className={styles.mobileCtaSchool}>
            <h3>{school.name}</h3>
            {school.state && school.city && (
              <div className={styles.mobileCtaLocation}>
                <TiLocation size={16} />
                <p>
                  {school.city}, {school.state}
                </p>
              </div>
            )}
          </div>

          <button type="button" className={styles.ctaButton} onClick={onCtaClick}>
            {cta?.label}
          </button>

          <button className={styles.continueToResults} onClick={handleDrawerClose} type="button">
            Continue browsing schools <HiOutlineChevronDoubleRight size={16} />
          </button>
        </div>
      )}
    </>
  );
};

export default ExitPageRevamp;
