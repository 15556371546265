import { type FC } from 'react';

import { appendAcronym } from '@core/shared/features/TcpaSection';
import { School } from '@core/ts/results';

import { replaceCTALabelInTCPA } from '../../utils/tcpa';
import styles from './TCPA.module.css';

type Props = {
  school?: School;
};

const TCPA: FC<Props> = ({ school }) => (
  <div className={styles.piiConfirmationTermsAndConditions}>
    <div className={styles.piiConfirmationTermsAndConditionsTitle}> Terms and Conditions </div>

    <span className={styles.schoolName} data-tf-element-role="consent-advertiser-name">
      {appendAcronym(school?.name || '')}:
    </span>
    <p
      data-tf-element-role="consent-language"
      dangerouslySetInnerHTML={{ __html: replaceCTALabelInTCPA(school?.config?.tcpa) ?? '' }}
    />
  </div>
);

export default TCPA;
