import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SchoolConfigData } from '@core/blocks/edu-match/components/ExitStrategy/type';
import useCheckScreen from '@core/blocks/eduResults/hooks/useCheckScreen';
import { LEAD_SUBMISSION_STEPS } from '@core/constants';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import { selectContactId } from '@core/reducers/contactSlice';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';
import {
  selectHeclidMap,
  selectProductCorrelationIdMap,
  selectFormContext,
  setHeclidMapEntry,
} from '@core/reducers/eventingSlice';
import { selectAllInputs, setInputs } from '@core/reducers/inputsSlice';
import { hideLeadDelivery, addSubmission } from '@core/reducers/matchesSlice';
import { submitLead, postGetLeadSchema, validateEmail, validatePhone } from '@core/services/leadDelivery';
import { mobiusRedirect } from '@core/services/mobius';
import { PageActions } from '@core/services/newRelic';
import AdditionalQuestions from '@core/shared/features/SingleProgramSubmission/AdditionalQuestions';
import { ILDSSchema } from '@core/ts/leadDelivery';
import { VoyagerResult } from '@core/ts/results';
import Diffy from '@core/utils/diffy';
import { phoneValidationFeatureFlagWrapper } from '@core/utils/functionWrappers/phoneValidationFeatureFlagWrapper';
import getToken from '@core/utils/getToken';
import { isUnderGraduate } from '@core/utils/isUnderGraduate';
import timer from '@core/utils/timer';

import SchoolDetails from '../../SchoolDetails';
import ExitPageRevamp from '../ExitPageRevamp';
import PreSubmissionPageRevamp from '../PreSubmissionPageRevamp';
import styles from './SingleProgramSubmissionInfoRevamp.module.css';

const { PII_CONFIRMATION, EXIT_PAGE, PRQ_FORM } = LEAD_SUBMISSION_STEPS;

type Props = {
  result: VoyagerResult;
  schoolConfigData: SchoolConfigData;
  slideOutStep: string;
  setSlideOutStep: Dispatch<SetStateAction<string>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const SingleProgramSubmissionInfoRevamp: FC<Props> = ({
  result,
  schoolConfigData,
  slideOutStep,
  setSlideOutStep,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const heclidMap = useSelector(selectHeclidMap);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);
  const inputs = useSelector(selectAllInputs);
  const formContext = useSelector(selectFormContext);
  const [currentSchema, setCurrentSchema] = useState<ILDSSchema | undefined>(undefined);
  const [prqSchema, setPrqSchema] = useState<ILDSSchema | undefined>(undefined);
  const diffy = new Diffy(inputs);
  const { school, program } = result;
  const [, setEditMode] = useState<boolean>(false);
  const [closePopup] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(inputs?.firstName?.value);
  const [lastName, setLastName] = useState<string>(inputs?.lastName?.value);
  const [email, setEmail] = useState<string>(inputs?.email?.value);
  const [phone, setPhone] = useState<string>(inputs?.phone?.value);
  const [formError, setFormError] = useState<{ field: string }[]>([]);
  const isHeclidReady = heclidMap[program?.id] !== undefined;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [degree] = useSelector(selectDcsDegrees);

  const { isMobile } = useCheckScreen();

  const { elementClicked } = useElementEvents({
    webElement: {
      elementType: 'exit-element',
      text: 'Skip to all results',
      location: 'drawer',
      htmlId: 'thank-you-button',
      name: 'skip',
    },
  });

  const isEmptyString = (str: String) => str === null || str?.match(/^ *$/) !== null;

  const flags = useFeatureFlags();

  const { formErrored } = useFormEvents({
    formContext,
    errorDetails: 'INVALID',
    errorMessage: 'Please provide your valid phone number.',
    errorType: 'Phone Validation',
  });

  const contactId = useSelector(selectContactId);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setFormError([]);
    let hasFormError = false;
    const formErrorArray = [];
    const inputArray = [
      { field: 'first name', value: firstName },
      { field: 'last name', value: lastName },
      { field: 'email', value: email },
      { field: 'phone', value: phone },
    ];
    for (const input of inputArray) {
      if (isEmptyString(input.value)) {
        hasFormError = true;
        formErrorArray.push({ field: input.field });
      }
    }

    if (/\d/.test(firstName)) {
      hasFormError = true;
      formErrorArray.push({ field: 'first name' });
    }

    if (/\d/.test(lastName)) {
      hasFormError = true;
      formErrorArray.push({ field: 'last name' });
    }

    if (email && !validateEmail(email)) {
      hasFormError = true;
      formErrorArray.push({ field: 'email' });
    }

    if (phone) {
      const { validPhoneNumber } = await validatePhone(phone);

      if (!validPhoneNumber) {
        hasFormError = true;
        formErrorArray.push({ field: 'phone' });
      }
    }

    if (hasFormError) {
      setFormError(formErrorArray);
      setIsSubmitting(false);
    } else {
      dispatch(
        setInputs([
          { key: 'firstName', value: firstName },
          { key: 'lastName', value: lastName },
          { key: 'email', value: email },
          { key: 'phone', value: phone },
        ])
      );
      if (prqSchema) {
        setSlideOutStep(PRQ_FORM);
      } else {
        setTimeout(() => {}, 3000);
        const actionParams = {
          ...flags,
          anonymousId: window._Cohesion.anonymousId,
          correlationId: productCorrelationIdMap[program?.id],
        };
        PageActions.LeadAttempt(actionParams);
        const leadInputs = {
          ...inputs,
          tcpaText: { key: 'tcpaText', value: schoolConfigData?.tcpa },
        };

        const res = await phoneValidationFeatureFlagWrapper({
          wrappedFunction: () =>
            submitLead(result, leadInputs, heclidMap[program?.id], productCorrelationIdMap[program?.id], contactId),
          inputs: leadInputs,
          flag: !!flags?.phoneValidation,
        });

        if (res?.message === 'invalid_phone_line_type_intelligence') {
          hasFormError = true;
          formErrorArray.push({ field: 'phone' });
          setFormError(formErrorArray);
          formErrored();
          setIsSubmitting(false);
          return;
        }

        if (res?.message) {
          await timer(2000);
          dispatch(hideLeadDelivery());
          setIsSubmitting(false);
          return;
        }
        dispatch(addSubmission(result));
        setSlideOutStep(EXIT_PAGE);

        PageActions.LeadSuccess(actionParams);
        setIsSubmitting(false);
      }
    }
  };
  const onClose = () => {
    if (closePopup) setOpen(false);
    elementClicked();
  };

  useEffect(() => {
    const getSchema = async () => {
      const schema = await postGetLeadSchema({
        schoolId: school?.id,
        programId: program?.id,
        body: {
          lead: {
            location: {
              postalCode: inputs?.zip?.value,
            },
          },
          trackingContext: {
            correlationId: productCorrelationIdMap[program?.id],
            publisher: window.location.host,
            heclid: heclidMap[program?.id],
            anonymousId: window._Cohesion.anonymousId,
            url: inputs?.sourceUrl?.value,
            experience: 'Voyager',
          },
        },
      });

      const diffSchema = await diffy.diffLdsSchema(schema, result?.leadSource);

      setCurrentSchema(schema);
      setPrqSchema(diffSchema);
    };
    getSchema();
  }, []);

  useEffect(() => {
    if (slideOutStep === PII_CONFIRMATION)
      mobiusRedirect({
        idToken: getToken(),
        productCorrelationId: productCorrelationIdMap[program?.id],
        link: result?.url,
        trackingContextOverride: {
          formatType: 'app',
        },
      }).then(({ heclid, error }) => {
        if (!error) {
          dispatch(setHeclidMapEntry({ key: program?.id, value: heclid }));
        }
      });
  }, []);

  return (
    <>
      <div className={styles.schoolDetailsContainer}>
        <SchoolDetails school={school} />
      </div>
      <div className={styles.body}>
        {slideOutStep === EXIT_PAGE && (
          <ExitPageRevamp schoolConfigData={schoolConfigData} result={result} setOpen={setOpen} />
        )}
        {slideOutStep === PII_CONFIRMATION && (
          <PreSubmissionPageRevamp
            isHeclidReady={isHeclidReady}
            isSubmitting={isSubmitting}
            result={result}
            schoolConfigData={schoolConfigData}
            handleSubmit={handleSubmit}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            setPhone={setPhone}
            piiLeadErrors={formError}
          />
        )}

        {slideOutStep === PRQ_FORM && (
          <AdditionalQuestions
            setEditMode={setEditMode}
            currentProgram={result}
            originalSchema={currentSchema}
            prqSchema={prqSchema}
            setStep={setSlideOutStep}
            isResultsRevamp
            setPiiLeadErrors={setFormError}
            formErrored={formErrored}
          />
        )}
      </div>
    </>
  );
};
export default SingleProgramSubmissionInfoRevamp;
