/* eslint-disable react/no-danger */
import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CompactCard } from '@core/blocks/edu-match/components';
import Dots from '@core/blocks/edu-match/components/Loading/Dots';
import { PiiFormSection } from '@core/blocks/edu-match/components/PiiFormSection';
import useCheckScreen from '@core/blocks/eduResults/hooks/useCheckScreen';
import { LEAD_SUBMISSION_STEPS } from '@core/constants';
import { useFeatureFlags } from '@core/context/FeatureFlagsContext';
import useSchoolConfig from '@core/hooks/useSchoolConfig';
import { selectDcsDegrees } from '@core/reducers/dcsSlice';
// Reducer's selectors/actions
import { setHeclidMapEntry, selectProductCorrelationIdMap, selectHeclidMap } from '@core/reducers/eventingSlice';
import { selectAllInputs } from '@core/reducers/inputsSlice';
import { addSubmission, hideLeadDelivery } from '@core/reducers/matchesSlice';
import { submitLead } from '@core/services/leadDelivery';
import { mobiusRedirect } from '@core/services/mobius';
import { PageActions } from '@core/services/newRelic';
import { getSchoolConfig } from '@core/services/schoolConfig';
// Components
import Icon from '@core/shared/components/Icon';
import PulsingDotLoader from '@core/shared/components/PulsingDotLoader';
import { VoyagerResult } from '@core/ts/results';
// Utils, Hooks & Services
import cn from '@core/utils/classNames';
import { phoneValidationFeatureFlagWrapper } from '@core/utils/functionWrappers/phoneValidationFeatureFlagWrapper';
import getToken from '@core/utils/getToken';
import { isUnderGraduate } from '@core/utils/isUnderGraduate';
import timer from '@core/utils/timer';

import { selectContactId } from '../../../../reducers/contactSlice';
import SubmissionStatus from '../../SubmissionStatus';
import TcpaSection from '../../TcpaSection';
// Styles
import styles from './PiiConfirmation.module.css';

const { ADDITIONAL_QUESTIONS, EXIT_STRATEGY, PII_CONFIRMATION } = LEAD_SUBMISSION_STEPS;
type PiiConfirmationProps = {
  setPiiLeadErrors: Dispatch<SetStateAction<{ field: string }[]>>;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  piiLeadErrors: { field: string }[];
  step: string;
  setStep: Dispatch<SetStateAction<string>>;
  currentProgram: VoyagerResult;
  prqSchema: any;
  formErrored: () => void;
  editMode: boolean;
};

// Gets or previews lead info
const PiiConfirmation = ({
  step,
  setStep,
  currentProgram,
  prqSchema,
  setPiiLeadErrors,
  piiLeadErrors,
  setEditMode,
  formErrored,
  editMode,
}: PiiConfirmationProps) => {
  // Inputs State properties
  const inputs = useSelector(selectAllInputs);

  // Eventing State properties
  const heclidMap = useSelector(selectHeclidMap);
  const productCorrelationIdMap = useSelector(selectProductCorrelationIdMap);

  // local state
  const [tcpa, setTcpa] = useState(undefined);

  const [tcpaLoading, setTcpaLoading] = useState(true);
  const [tcpaErrorMsg, setTcpaErrorMsg] = useState(undefined);

  const [submissionStatus, setSubmissionStatus] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isHeclidReady = heclidMap[currentProgram?.program.id] !== undefined;

  const flags = useFeatureFlags();
  // Action Dispatcher
  const dispatch = useDispatch();

  const contactId = useSelector(selectContactId);

  const schoolConfig = useSchoolConfig(currentProgram?.school?.id);

  const [degree] = useSelector(selectDcsDegrees);

  const isGrad = !isUnderGraduate(degree);

  const { isMobile } = useCheckScreen();

  const isMobileUndergrad = isMobile && !isGrad;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (prqSchema) {
      setStep(ADDITIONAL_QUESTIONS);
      return;
    }

    const actionParams = {
      ...flags,
      anonymousId: window._Cohesion.anonymousId,
      correlationId: productCorrelationIdMap[currentProgram?.program?.id],
    };
    PageActions.LeadAttempt(actionParams);
    const leadInputs = {
      ...inputs,
      tcpaText: { key: 'tcpaText', value: schoolConfig?.data?.tcpa },
    };
    const res = await phoneValidationFeatureFlagWrapper({
      wrappedFunction: () =>
        submitLead(
          currentProgram,
          leadInputs,
          heclidMap[currentProgram?.program?.id],
          productCorrelationIdMap[currentProgram?.program?.id],
          contactId
        ),
      inputs: leadInputs,
      flag: !!flags?.phoneValidation,
    });
    if (res?.message === 'invalid_phone_line_type_intelligence') {
      setIsSubmitting(false);
      setEditMode(true);
      setPiiLeadErrors((state) => [...state, { field: 'phone' }]);
      setSubmissionStatus('');
      formErrored();
      return;
    }

    if (res?.message) {
      setIsSubmitting(false);
      setSubmissionStatus('ERROR');
      await timer(2000);
      dispatch(hideLeadDelivery());
    } else {
      PageActions.LeadSuccess(actionParams);
      dispatch(addSubmission(currentProgram));
      setStep(EXIT_STRATEGY);
    }
  };

  useEffect(() => {
    if (step === PII_CONFIRMATION)
      mobiusRedirect({
        idToken: getToken(),
        productCorrelationId: productCorrelationIdMap[currentProgram?.program?.id],
        link: currentProgram?.url,
        trackingContextOverride: {
          formatType: 'app',
          formatSubtype: 'grid',
        },
      }).then(({ heclid, error }) => {
        if (!error) {
          dispatch(setHeclidMapEntry({ key: currentProgram?.program?.id, value: heclid }));
        }
      });
  }, []);

  useEffect(() => {
    if (!inputs.firstName?.value || !inputs.lastName?.value || !inputs.email?.value || !inputs.phone?.value) {
      setEditMode(true);
    }
  }, [inputs]);

  // when currentProgram is set use getSchoolConfig to get tcpa
  useEffect(() => {
    if (currentProgram) {
      const getTcpa = async () => {
        const schoolConfig = await getSchoolConfig(currentProgram?.school?.id);

        if (schoolConfig.error) {
          setTcpaErrorMsg('We ran into an issue! Please try again.');
          await timer(2500);
          dispatch(hideLeadDelivery());
          return;
        }

        setTcpa({ ...schoolConfig, hit: currentProgram });
        setTcpaLoading(false);
      };
      getTcpa();
    }
  }, [currentProgram]);

  if (tcpaLoading)
    return (
      <section className={styles.loadingContainer}>
        {tcpaErrorMsg ? (
          <h2>{tcpaErrorMsg}</h2>
        ) : (
          <>
            <h2>Loading...</h2>
            <Dots />
          </>
        )}
      </section>
    );

  const ctaText = isGrad || isMobileUndergrad ? 'Learn More' : 'Submit Info';

  const buttonText = isSubmitting || !isHeclidReady ? <PulsingDotLoader /> : ctaText;

  return submissionStatus ? (
    <SubmissionStatus status={submissionStatus} schoolName={currentProgram?.school?.name} />
  ) : (
    <form onSubmit={(e) => e.preventDefault()} data-tf-element-role="offer" className={styles.piiConfirmation}>
      <div className={styles.piiSection}>
        <div className={styles.title}>
          <Icon name="yes" color="--primary-700" size="8" />
          <h2>{isGrad ? 'Want to Learn More?' : 'Request Information'}</h2>
        </div>
        <p className={styles.subtitle}>
          {isGrad
            ? "Submit your contact information to show this school you're interested! You can even start your application on the next screen."
            : 'Great choice! Submit your information and we’ll share some exciting details on what to expect next.'}
        </p>

        <CompactCard hit={currentProgram} showClose={false} />

        <h4>
          {isGrad ? (
            <>Before submitting, please confirm your info:</>
          ) : (
            <>Before you submit, let&apos;s make sure your information is correct.</>
          )}
        </h4>
        {/* View/Edit personal info */}
        <div className={styles.infoPanel}>
          <PiiFormSection
            setEditMode={setEditMode}
            editMode={editMode}
            piiLeadErrors={piiLeadErrors}
            location="single-program"
          />
        </div>

        <TcpaSection tcpa={tcpa ? [tcpa] : null} />
      </div>
      <div className={styles.submitBtnSection}>
        <p className={styles.ctaTitle}>
          Double check your information before submitting{isGrad ? <></> : <>&nbsp;👆</>}
        </p>
        <p className={styles.ctaSubtitle}>
          {isGrad ? <></> : <>Your information will never be shared with a school without your consent.</>}
        </p>
        <button
          className={cn(styles.submitInfoBtn, (editMode || !isHeclidReady) && styles.disabledSubmitInfoBtn)}
          disabled={editMode || !isHeclidReady || isSubmitting}
          id="reward_click_lds"
          data-testid="edumatch-leaddelivery-submit"
          data-tf-element-role="submit"
          onClick={handleSubmit}
          type="button"
        >
          {buttonText}
        </button>
      </div>
    </form>
  );
};

export default PiiConfirmation;
